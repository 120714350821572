import React, { useState, useRef } from 'react';
import { navigate } from '@reach/router';

import SectionHeading from './SectionHeading';
import FormLabel from './FormLabel';
import Button from './Button';
import TextInput from './TextInput';
import Textarea from './Textarea';
import Select from './Select';
import Checkbox from './Checkbox';

export default function LetsTalk() {
  const emailRef = useRef(null);
  const formRef = useRef(null);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState(`I'm interested in working with you`);

  async function submitForm(e) {
    e.preventDefault();

    const form = formRef.current;
    const data = new FormData(form);
    const isValid = form.checkValidity();

    if (isValid) {
      setIsSending(true);

      let json = {};
      data.forEach((v, k) => {
        json[k] = v;
      });

      const res = await fetch(form.action, {
        headers: { 'Content-Type': 'application/json' },
        method: form.method,
        body: JSON.stringify(json)
      });

      res.status >= 200 && res.status < 300
        ? navigate('/success')
        : setError('Something went wrong. Please try again later.');
    } else {
      form.reportValidity();
    }
  }

  return (
    <div className="lets-talk">
      <SectionHeading>Let's talk</SectionHeading>
      <p>
        Please fill out the form below if you’re interested in working together,
        having me speak at your meetup or conference, or if you have any other
        questions. If filling out forms isn't your thing, feel free to shoot me
        an email at brian [at] briankulp [dot] co. Thanks!
      </p>

      <form
        ref={formRef}
        className="lets-talk__form"
        action="https://formspree.io/xbadawem"
        method="POST"
      >
        <div className="lets-talk__row">
          <FormLabel text="Your email (required)">
            <TextInput
              ref={emailRef}
              type="email"
              name="_replyto"
              required
              value={email}
              onInvalid={() => emailRef.current.scrollIntoView(false)}
              onChange={e => setEmail(e.target.value)}
            />
          </FormLabel>

          <FormLabel text="What do you want to talk about?">
            <Select
              name="Subject"
              value={subject}
              onChange={e => setSubject(e.target.value)}
            >
              <option>I'm interested in working with you</option>
              <option>I want you to speak at our conference</option>
              <option>Other</option>
            </Select>
          </FormLabel>
        </div>

        {subject === `I'm interested in working with you` && (
          <>
            <div className="lets-talk__row">
              <FormLabel text="When does the project start?">
                <Select name="Start date">
                  <option>As soon as possible</option>
                  <option>Within the next couple of weeks</option>
                  <option>Next month</option>
                  <option>Within a few months</option>
                  <option>Someday</option>
                </Select>
              </FormLabel>

              <FormLabel text="When does it need to be finished?">
                <Select name="End date">
                  <option>As soon as possible</option>
                  <option>Within the next couple of weeks</option>
                  <option>Next month</option>
                  <option>Within a few months</option>
                  <option>Within the year</option>
                  <option>Someday</option>
                </Select>
              </FormLabel>
            </div>

            <div className="lets-talk__row">
              <FormLabel text="What's the estimated budget for this project?">
                <Select name="Budget">
                  <option>{`< $25,000`}</option>
                  <option>{`$25,000 – $50,000`}</option>
                  <option>{`$50,000 – $100,000`}</option>
                  <option>{`>$100,000`}</option>
                </Select>
              </FormLabel>
              <div /> {/* leaves the right side of the row empty */}
            </div>

            <div className="lets-talk__row">
              <div className="lets-talk__services">
                <FormLabel text="What services are you wanting to hire me for?" />

                <Checkbox name="Service / Design">Design</Checkbox>
                <Checkbox name="Service / Development">Development</Checkbox>
                <Checkbox name="Service / Strategy">
                  Strategy / Ideation / Planning
                </Checkbox>
              </div>
            </div>

            <FormLabel text="Anything else you'd like me to know?">
              <Textarea name="Comments" />
            </FormLabel>
          </>
        )}

        {subject === `I want you to speak at our conference` && (
          <>
            <div className="lets-talk__row">
              <FormLabel text="Where is the conference?">
                <TextInput name="Where is the conference?" />
              </FormLabel>

              <FormLabel text="When is the conference?">
                <TextInput name="When is the conference?" />
              </FormLabel>
            </div>

            <FormLabel text="Is there a particular topic you want me to speak on?">
              <Textarea name="Conference Topic" />
            </FormLabel>
          </>
        )}

        {subject === `Other` && (
          <FormLabel text="What would you like to talk about?">
            <Textarea name="Other:" />
          </FormLabel>
        )}

        <div className="lets-talk__submit">
          <Button
            type="submit"
            disabled={isSending}
            onClick={e => submitForm(e)}
          >
            {isSending ? 'Sending...' : 'Send'}
          </Button>

          {!error && <div className="lets-talk__submit-error">{error}</div>}
        </div>
      </form>
    </div>
  );
}
