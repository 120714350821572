import React from 'react';

const FormLabel = ({ children, text }) => (
  <label className="form-label">
    {text && <span className="form-label__text">{text}</span>}
    {children}
  </label>
);

export default FormLabel;
