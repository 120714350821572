import React from 'react';

const Checkbox = ({ children, ...checkboxProps }) => (
  <div className="checkbox">
    <label className="checkbox__label">
      <input className="checkbox__input" type="checkbox" {...checkboxProps} />
      <div className="checkbox__check" />
      <span className="checkbox__label-text">{children}</span>
    </label>
  </div>
);

export default Checkbox;
