import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import LetsTalk from '../components/LetsTalk';

const LetsTalkPage = ({ location }) => (
  <Layout currentPath={location.pathname} scroll>
    <SEO
      title="Brian Kulp Co. | Let's talk"
      keywords={[`brian kulp`, `seattle`, `designer`, `developer`]}
    />
    <LetsTalk />
  </Layout>
);

export default LetsTalkPage;
